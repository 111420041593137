body {
  margin: 0px;
  background-color: "#0e0e0e";
  color: white;
}

.tradingview-widget-container {
  background-color: black;
  filter: invert(0%);
  color:  rgba(0,0,0,0);
}

.App {
  font-family: sans-serif;
  text-align: center;
  min-height: 100vh;
  height: 100%;
  
}

.matic-chart-container {
  filter: invert(100%);
  width: 100%;
}

.bnb-chart-container {
  filter: invert(100%);
  width: 100%;
}
.navbarglow {
  box-shadow: 0px 1px 12px #814cf4;
}

.transparent {
  color: rgba(0,0,0,0)
}


.tradingview-widget-container iframe {
  position: absolute;
  top: 0;
  bottom: 50;
}


.connect {
  color:#814cf4;
}